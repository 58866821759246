import { createSelector } from '@reduxjs/toolkit';
import { State, stateSelector } from '../State';
import { createParameterSelector } from '../useParamsSelector';
import { notLoadedState, PaginatedDataParams, SortedDataParams } from '../commonTypes';
import { ContractListPaginatedParams } from './contractListTypes';

type QueryParameters<T> = Array<T> | undefined | null

const pageNumberParamSelector = createParameterSelector<PaginatedDataParams, number>(
  (params) => params.PageNumber ?? 0
);
const pageSizeParamSelector = createParameterSelector<PaginatedDataParams, number>(
  (params) => params.PageSize ?? 0
);
const sortFieldNameParamSelector = createParameterSelector<SortedDataParams, string | null>(
  (params) => params.SortFieldName
);
const orderByParamSelector = createParameterSelector<SortedDataParams, boolean | null>(
  (params) => params.OrderBy
);
const searchQueryParamSelector = createParameterSelector<ContractListPaginatedParams, string | undefined>(
  (params) => params.SearchQuery
);
const hasFileNameParamSelector = createParameterSelector<ContractListPaginatedParams, boolean | undefined>(
  (params) => params.HasFileName
);
const endDateFilterParamSelector = createParameterSelector<ContractListPaginatedParams, Date[] | undefined>(
  (params) => params.EndDate
);
const statusFilterParamSelector = createParameterSelector<
  ContractListPaginatedParams,
  QueryParameters<number>
>((params) => params.Status);
const contractTypesParamSelector = createParameterSelector<
  ContractListPaginatedParams,
  QueryParameters<string>
>((params) => params.ContractTypes);
const tourCodeParamSelector = createParameterSelector<
  ContractListPaginatedParams,
  QueryParameters<string>
>((params) => params.TourCodes);
const ticketDesignatorParamSelector = createParameterSelector<
  ContractListPaginatedParams,
  QueryParameters<string>
>((params) => params.TicketDesignators);
const validatingCarriersFilterParamSelector = createParameterSelector<
  ContractListPaginatedParams,
  QueryParameters<string>
>((params) => params.ValidatingCarriers);
const customersFilterParamSelector = createParameterSelector<
  ContractListPaginatedParams,
  QueryParameters<string>
>((params) => params.Customers);
const versionFilterParamSelector = createParameterSelector<
  ContractListPaginatedParams,
  QueryParameters<number | null>
>((params) => params.Version);

export const activePageSelector = (state: State) => (state.contractList.activePage ?? 0) + 1;

export const contractListKeySelector = createSelector(
  pageSizeParamSelector,
  searchQueryParamSelector,
  hasFileNameParamSelector,
  sortFieldNameParamSelector,
  orderByParamSelector,
  endDateFilterParamSelector,
  statusFilterParamSelector,
  validatingCarriersFilterParamSelector,
  customersFilterParamSelector,
  versionFilterParamSelector,
  contractTypesParamSelector,
  ticketDesignatorParamSelector,
  tourCodeParamSelector,
  (
    pageSize,
    searchQuery,
    hasFileName,
    sortFieldName,
    orderBy,
    endDate,
    status,
    validatingCarriers,
    customers,
    version,
    contractType,
    ticketDesignator,
    tourCode
  ) =>
    `${pageSize}_${typeof hasFileName === 'boolean' ? hasFileName : ''}_${searchQuery ?? ''}_${
      sortFieldName ?? ''
    }_${typeof orderBy === 'boolean' ? orderBy : ''}` +
    `_${endDate?.join(',') ?? ''}_${status?.join(',') ?? ''}_${validatingCarriers?.join(',') ?? ''}_${
      customers?.join(',') ?? ''
    }_${version?.map((v) => v ?? 'null').join(',') ?? ''}_${contractType?.join(',') ?? ''}
    _${ticketDesignator?.map((v) => v ?? 'null').join(',') ?? ''}_${tourCode?.join(',') ?? ''}`
);

const contractListPageStateSelector = createSelector(
  (state: State) => state,
  contractListKeySelector,
  pageNumberParamSelector,
  (state, key, pageNumber) =>
    state.contractList.key === key ? state.contractList.pages?.[pageNumber] : undefined
);

export const contractListPageLoadStateSelector = createSelector(
  contractListPageStateSelector,
  (page) => page?.loadState ?? notLoadedState
);

export const contractListPageSelector = createSelector(contractListPageStateSelector, (page) => page?.data);

export const contractListTotalCountSelector = createSelector(
  (state: State) => state,
  contractListKeySelector,
  (state, key) => (state.contractList.key === key ? state.contractList.totalCount : 0)
);

export const contractListFilterOptionsSelector = createSelector(
  stateSelector,
  contractListKeySelector,
  (state, key) => (state.contractList.key === key ? state.contractList.filterOptions : undefined)
);

export const contractListSelectedValuesSelector = createSelector(
  stateSelector,
  (state) => {
    const filters = state.contractList.selectedFilters;
    const date = filters.EndDate?.map(utcString => new Date(Date.parse(utcString)));
    return {
      ...filters,
      EndDate: date 
    };
  });
