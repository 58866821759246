import { union } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Grid, Icon, Label } from 'semantic-ui-react';
import { ContractFilterOptions } from '../../../api/types';
import CheckboxDropdown, { CheckboxDropdownOption } from '../../../components/CheckboxDropdown';
import DatesSelector from '../../../components/DatesSelector';
import formatDecimal from '../../../services/utils/formatDecimal';
import settings from '../../../settings';
import { loadCarriers } from '../../../store/carriers/carriersActions';
import { carriersByCodeSelector } from '../../../store/carriers/carriersSelectors';
import { CarrierListItem } from '../../../store/carriers/carriersTypes';
import { ContractStatus } from '../../../store/contractList/contractListTypes';
import './ContractListFilterPanel.scss';
import { useAppDispatch } from '../../../store/useAppDispatch';
import { resetSelectedFilterValues, updateSelectedFilterValues } from '../../../store/contractList/contractListActions';

interface ContractListFilterPanelProps {
  filterOptions?: ContractFilterOptions | null;
  selected?: ContractListFilters;
  editMode?: boolean;
}

export interface ContractListFilters extends ContractFilterOptions {
  EndDate?: Date[];
}

const getCarrierText = (carrier?: CarrierListItem) => `${carrier?.name} (${carrier?.code})`;
const getVersionText = (value: number | null) =>
  value === null ? 'Blank' : formatDecimal(value)?.toString() ?? '';
const getStatusText = (value: number) => ContractStatus[value];

const getContractTypeText = (value: string) => value;
const getEndDateText = (dates: Date[]) =>
  moment(dates[0]).format(settings.dateFormat) +
  (dates[1] ? ' - ' + moment(dates[1]).format(settings.dateFormat) : '');

const toTextValueOption = (value: string): CheckboxDropdownOption => {
  return { text: value, value: value }
}

const ContractListFilterPanel: React.FunctionComponent<ContractListFilterPanelProps> = ({
  filterOptions,
  selected,
  editMode,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadCarriers());
  }, []);

  const carriers = useSelector(
    carriersByCodeSelector(union(filterOptions?.ValidatingCarriers ?? [], selected?.ValidatingCarriers))
  );

  const versionOptions = useMemo(
    () =>
      union(filterOptions?.Version, selected?.Version).map(
        (v) => ({ text: getVersionText(v), value: v } as CheckboxDropdownOption)
      ),
    [filterOptions?.Version, selected?.Version]
  );
  const validatingCarrierOptions = useMemo(
    () =>
      carriers
        ?.map((c) => ({ text: getCarrierText(c), value: c.code } as CheckboxDropdownOption))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [carriers]
  );
  const statusOptions = useMemo(
    () =>
      union(filterOptions?.Status, selected?.Status).map(
        (v) => ({ text: getStatusText(v), value: v } as CheckboxDropdownOption)
      ),
    [filterOptions?.Status]
  );
  const customerOptions = useMemo(
    () =>
      union(filterOptions?.Customers, selected?.Customers).map(
        (v) => toTextValueOption(v)
      ),
    [filterOptions?.Customers]
  );
  const contractTypeOptions = useMemo(
    () =>
      union(filterOptions?.ContractTypes, selected?.ContractTypes).map(
        (v) => toTextValueOption(v)
      ),
    [filterOptions?.ContractTypes]
  );
  const ticketDesignatorOptions = useMemo(
    () =>
      union(filterOptions?.TicketDesignators, selected?.TicketDesignators).map(
        (v) => toTextValueOption(v)
      ),
    [filterOptions?.TicketDesignators]
  );
  const tourCodeOptions = useMemo(
    () =>
      union(filterOptions?.TourCodes, selected?.TourCodes).map(
        (v) => toTextValueOption(v)
      ),
    [filterOptions?.TourCodes]
  );
  
  const handleChange = (fieldName: keyof ContractListFilters) => (data: any) => {
    const newValue = { ...selected };
    newValue[fieldName] = data;
    
    dispatch(updateSelectedFilterValues(newValue));
  };

  const handleFilterRemoved = (fieldName: keyof ContractListFilters, item: any, removeField?: boolean) => {
    const newValue = ({ ...selected }) as any;
    newValue[fieldName] = removeField ? undefined : (selected?.[fieldName] as any[]).filter((v) => v !== item);

    dispatch(updateSelectedFilterValues(newValue));
  };

  const renderFilterLabel = (
    fieldName: keyof ContractListFilters,
    fieldValueText: string,
    displayName?: string,
    fieldValue?: any,
    removeField?: boolean
  ) => {
    return (
      <Label className="filter-value-label">
        {`${displayName ?? fieldName}: ${fieldValueText}`}
        {editMode ? (
          <Icon name="delete" onClick={() => handleFilterRemoved(fieldName, fieldValue, removeField)} />
        ) : undefined}
      </Label>
    );
  };

  const resetFilters = useCallback(() => dispatch(resetSelectedFilterValues()),[dispatch]);
  const isSelected = (values?: Array<unknown> | null) => (values?.length ?? 0) > 0;
  
  const versionsSelected = isSelected(selected?.Version);
  const validatingCarriersSelected = isSelected(selected?.ValidatingCarriers);
  const customersSelected = isSelected(selected?.Customers);
  const statusSelected = isSelected(selected?.Status);
  const endDateSelected = isSelected(selected?.EndDate);
  const contractTypeSelected = isSelected(selected?.ContractTypes);
  const ticketDesignatorsSelected = isSelected(selected?.TicketDesignators);
  const tourCodesSelected = isSelected(selected?.TourCodes);
  const filtersSelected =
    versionsSelected 
    || customersSelected
    || validatingCarriersSelected
    || statusSelected 
    || endDateSelected 
    || contractTypeSelected 
    || ticketDesignatorsSelected 
    || tourCodesSelected;

  return (
    <>
      {editMode ? (
        <Form>
          <Grid className="contract-filters">
            <Grid.Row>
              <Grid.Column width="5">
                <Form.Field>
                  <CheckboxDropdown
                    options={versionOptions}
                    placeholder="Version number"
                    label={'Version'}
                    selectAll
                    value={selected?.Version}
                    onChange={handleChange('Version')}
                    searchable
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="5">
                <Form.Field>
                  <CheckboxDropdown
                    options={customerOptions}
                    searchable
                    label={'Customer'}
                    placeholder="Customer name"
                    selectAll
                    value={selected?.Customers}
                    onChange={handleChange('Customers')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="5">
                <Form.Field>
                  <CheckboxDropdown
                    options={validatingCarrierOptions}
                    label={'Airline'}
                    searchable
                    placeholder="Airline name"
                    selectAll
                    value={selected?.ValidatingCarriers}
                    onChange={handleChange('ValidatingCarriers')}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="5">
                <Form.Field>
                  <CheckboxDropdown
                    searchable
                    label={'Status'}
                    options={statusOptions}
                    placeholder="Contract status"
                    value={selected?.Status}
                    onChange={handleChange('Status')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="5">
                <Form.Field>
                  <CheckboxDropdown
                    label={'Contract Type'}
                    searchable
                    options={contractTypeOptions}
                    placeholder="Contract type"
                    value={selected?.ContractTypes}
                    onChange={handleChange('ContractTypes')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="6">
                <Form.Field>
                  <label>End Date</label>
                  <DatesSelector
                    onChange={(data) => handleChange('EndDate')(data[0])}
                    value={selected?.EndDate ? [selected?.EndDate] : undefined}
                    multiple={false}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="5">
                <Form.Field>
                  <CheckboxDropdown
                    label={'Tour Code'}
                    searchable
                    options={tourCodeOptions}
                    placeholder="Tour code"
                    value={selected?.TourCodes}
                    onChange={handleChange('TourCodes')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="5">
                <Form.Field>
                  <CheckboxDropdown
                    label={'Ticket Designator'}
                    searchable
                    options={ticketDesignatorOptions}
                    placeholder="Ticket designator"
                    value={selected?.TicketDesignators}
                    onChange={handleChange('TicketDesignators')}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      ) : undefined}
      {filtersSelected && (
        <div className="contract-filters-values">
          {versionsSelected &&
            selected?.Version?.map((v) => renderFilterLabel('Version', getVersionText(v), undefined, v))}
          {validatingCarriersSelected &&
            validatingCarrierOptions
              .filter((c) => selected?.ValidatingCarriers?.includes(c.value as string))
              .map((c) => renderFilterLabel('ValidatingCarriers', c.text, 'Airline', c.value))}
          {customersSelected &&
            customerOptions
              .filter((c) => selected?.Customers?.includes(c.value as string))
              .map((c) => renderFilterLabel('Customers', c.text, 'Customer', c.value))}
          {statusSelected &&
            selected?.Status?.map((v) => renderFilterLabel('Status', getStatusText(v), undefined, v))}
          {contractTypeSelected &&
            selected?.ContractTypes?.map((v) => renderFilterLabel('ContractTypes', getContractTypeText(v), "Type", v))}
          {tourCodesSelected &&
            selected?.TourCodes?.map((v) => renderFilterLabel('TourCodes', v, "Tour Code", v))}
          {ticketDesignatorsSelected &&
            selected?.TicketDesignators?.map((v) => renderFilterLabel('TicketDesignators', v, "Ticket Designator", v))}
          {endDateSelected &&
            renderFilterLabel(
              'EndDate',
              getEndDateText(selected?.EndDate as Date[]),
              'End Date',
              undefined,
              true
            )}
        </div>
      )}
      {editMode && filtersSelected && (
        <Button
          className="contract-filters-values-clear-all"
          color="blue"
          size="mini"
          basic
          onClick={resetFilters} >
          Clear All
        </Button>
      )}
    </>
  );
};

export default ContractListFilterPanel;
